<template>
  <template
    v-for="{ section, items, type } in groupedSections"
    :key="section?.sys.id ?? items[0].sys.id"
  >
    <MappedComponents v-if="type === 'sectionItemEntry'" :items="items" />
    <ContentfulContentSection v-else :section="section" :items="items" />
  </template>
</template>

<script setup lang="ts">
import type { Entry } from 'contentful';
import { groupSections } from '~~/utils/groupSections';
import { isDefined } from '~/utils/guards/isDefined';

interface Props {
  // if an entry was deleted but is still referenced, contentful returns
  // `undefined`.
  // @see https://gcp.baslerweb.com/jira/browse/WEB2-2536
  entries: (Entry<unknown> | undefined)[];
}

const props = defineProps<Props>();

const groupedSections = computed(() =>
  groupSections(props.entries.filter(isDefined))
);
</script>
